import React from "react";
import { InputJson } from "nq-component";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import withRouter from "../../withRouter";
import BaseFormPage from "../../base/BaseFormPage";
import NavBar from "../../components/navbar";
import FormFactory2 from "../../components/FormFactory2";
import { restUseCase } from "../../usecases/rest";
import ProjectFormPresenter from "./ProjectFormPresenter";
import InputFactory from "../../components/InputFactory";
import Drop from "../../components/DropdownFilter";

class ProjectFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.state = {
      object: {},
      advanced: false,
      filtered: [],
    };
    this.presenter = new ProjectFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      restUseCase(),
      findObjectUseCase()
    );
  }
  getAcl() {
    const user = this.getCurrentUser();
    return {
      read: ["*", user.id, "role:admin", "role:masterAdmin"],
      write: [user.id, "role:admin", "role:masterAdmin", "role:Sales"],
    };
  }

  getCollectionName() {
    return "projects";
  }

  onClickAdvance() {
    this.setState({
      advanced: !this.state.advanced,
    });
  }

  onChangeObject(object) {
    this.presenter.onChangeObject(object);
  }

  getCurrentDateFormatted() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  render() {
    const object = this.state.object;
    console.log("ob", object);
    const currentDate = this.getCurrentDateFormatted();
    const advanced = this.state.advanced;
    const schema = this.getSchema(this.getCollectionName());
    if (!schema) return <h1>no schema</h1>;
    const label = this.getObjectId() === undefined ? "Add New " : "Edit ";
    return (
      <>
        <NavBar
          action={() => (
            <div className="dropdown dropstart d-inline-block">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickAdvance.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-gear pe-2" />
                  Toggle Advance
                </button>
              </div>
            </div>
          )}
        />
        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <h1 className="fw-bold mt-3 text-capitalize">{label} Project</h1>
              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  <div className="row g-3">
                    {advanced ? (
                      <InputJson
                        defaultValue={object}
                        onChange={this.onChangeObject.bind(this)}
                        rows="15"
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="mt-3 mb-3">
                    <label className="form-label">Project Name</label>
                    <InputFactory
                      type="String"
                      dynamic={false}
                      field="name"
                      object={object}
                      placeholder="Enter Project Name"
                      onChange={this.onChange.bind(this)}
                      required={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Client</label>
                    <InputFactory
                      type="UserInputPointer"
                      target="clients"
                      dynamic={false}
                      field="client"
                      object={object}
                      placeholder="Select Client"
                      onChange={this.onChange.bind(this)}
                      required={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Description</label>
                    <InputFactory
                      type="String"
                      dynamic={false}
                      field="description"
                      object={object}
                      placeholder="Enter Description"
                      onChange={this.onChange.bind(this)}
                      required={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Categories</label>
                    <InputFactory
                      type="String"
                      _type="Enum"
                      field="categories"
                      object={object}
                      onChange={this.onChange.bind(this)}
                      required
                      options={["Construction", "Event", "Fabrication"]}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Start Date</label>
                    <InputFactory
                      type="Date"
                      dynamic={false}
                      field="startDate"
                      object={object}
                      placeholder="Enter Start Date"
                      onChange={this.onChange.bind(this)}
                      required={true}
                      // min={currentDate}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">End Date</label>
                    <InputFactory
                      type="Date"
                      dynamic={false}
                      field="endDate"
                      object={object}
                      placeholder="Enter End Date"
                      onChange={this.onChange.bind(this)}
                      required={true}
                      min={currentDate}
                    />
                  </div>

                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary fs-sm me-3"
                    >
                      <i className="bi bi-file-earmark-check me-2"></i>SAVE
                    </button>
                    <button
                      type="button"
                      className="btn btn-light fs-sm"
                      onClick={this.onClickBack.bind(this)}
                    >
                      GO BACK
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ProjectFormPage);
