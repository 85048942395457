import React from "react";
import Checkbox from "../Checkbox";

const formatDate = (dateString) => {
  const providedDate = new Date(dateString);
  providedDate.setHours(0, 0, 0, 0);

  const options = { year: "numeric", month: "short", day: "numeric" };
  return providedDate.toLocaleDateString("en-US", options);
};

function Body({
  objects,
  fields,
  excludeFields,
  selected,
  selectable,
  user,
  onSelect,
  onClick,
  onClickItem,
}) {
  return objects.map((object, index) => {
    const id = object.id || object._id;
    const checked = selected.includes(object);
    return (
      <div key={id} className="card mb-3">
        <div className="card-body">
          <h4 className="card-title d-flex align-items-center">
            {selectable &&
              user.roles.some(
                (role) => role.id === "masterAdmin" || role.id === "hrAdmin"
              ) && (
                <div className="" style={{ backgroundColor: "rgba(0,0,0,0)" }}>
                  <Checkbox
                    checked={checked}
                    id={"check_" + id}
                    onChange={() => onSelect(index)}
                  />
                </div>
              )}
            <div>
              {object.title} -
              <span className="fs-6"> {formatDate(object.createdAt)}</span>
            </div>
          </h4>

          <p className="card-text">{object.description}</p>
          {object.fileAttachment ? (
            <a
              className="card-link"
              href={object.fileAttachment}
              style={{ color: "#cf6f00" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="bi bi-paperclip"></i>
              File Attachment
            </a>
          ) : (
            <p>No File Attachment</p>
          )}
          {user.roles.some(
            (role) => role.name === "masterAdmin" || role.name === "hrAdmin"
          ) && (
            <div>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => onClickItem(index)}
              >
                EDIT
              </button>
            </div>
          )}
        </div>
      </div>
    );
  });
}

export default Body;
